<template>
  <main class="d-flex justify-content-center mt-5">
    <article class="error-container">
      <section class="box-col-1 full-width">
        <header></header>
        <main class="page">
          <div class="titlebar">
            <div class="dot close"></div>
            <div class="dot minimise"></div>
            <div class="dot maximise"></div>
          </div>
          <pre>
            <code>
$ cat custodian.yml

policies:
<span class="green">-</span> name: <span class="green">delete-this-page</span>
  resource: <span class="green">stacklet</span>
  filters:
  <span class="green">-</span> type: <span class="green">website</span>
    page: {{route.params.catchAll}}
  actions:
  <span class="green">- delete</span>

$ custodian run custodian.yml
custodian.policy: delete-this-page resource: stacklet.website has count:1 time:1.38
custodian.policy: delete-this-page action: delete: 1 execution_time: 0.67
            </code>
          </pre>
        </main>
      </section>
    </article>
  </main>
</template>

<script>
import { useRoute } from "vue-router";

export default {
  name: "NotFound",
  setup() {
    const route = useRoute();
    return {
      route,
    };
  },
};
</script>

<style lang="scss" scoped>
.error-container {
  width: 75%;
}

code {
  font-family: monospace;
}

.page {
  background-color: $black;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  color: #ddd;
}

.page .titlebar {
  background-color: #ededed;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-sizing: border-box;
  height: 44px;
  padding: 16px;
}

.dot {
  background-color: #ccc;
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  width: 12px;
}

.dot.close {
  background-color: #ef6866;
}

.dot.minimise {
  background-color: #f5ed49;
}

.dot.maximise {
  background-color: #89c665;
}

.dot + .dot {
  margin-left: 7px;
}

.box-col-1 {
  width: 100%;
}

.box-col-1 pre {
  margin: 0;
  padding: 20px;
}

.box-col-1 code {
  font-size: 1.5em;
}

.green {
  color: #a6e22e;
}
</style>
